import moment from "moment";
import { getTokenAlignmentStatus, isEmpty } from "@utils/wallet/numberFormet.util";
import { Box, Typography } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import { IAllRegistryTokens } from "@utils/wallet/wallet";
import { palette } from "@styles/theme";
import { CryptoPriceCard, getPriceLable } from "./PriceCard";
import { EViewSize } from "@enums";

interface IcardContent {
  NFTList: IAllRegistryTokens;
  styleId?: string;
  artistRelated?: boolean;
  status: string;
}

export const ViewAllCardContent = styled(
  ({ NFTList, styleId, artistRelated, status, ...props }: IcardContent) => {
    const TitleDetails: string[] = [];
    if (!isEmpty(NFTList?.Artist?.artistName)){
      TitleDetails.push(NFTList?.Artist?.artistName);}
    if (!isEmpty(NFTList?.MetaData?.name)) {
      TitleDetails.push(NFTList?.MetaData?.name);
    } else if(!isEmpty(NFTList?.TokenID)) {
      TitleDetails.push(`# ${NFTList?.TokenID}`);
    }
    if (NFTList.MintedAt)
      TitleDetails.push(moment(NFTList.MintedAt).format("YYYY"));

    return (
      <div {...props}>
        <Box id={styleId ?? ""}>
          <Typography className="view-title">
            {TitleDetails?.[0]?.length > 40
              ? TitleDetails?.[0]?.substring(0, 40) + "..."
              : TitleDetails?.[0] ?? ""}
            {/* {+NFTList?.balance > 1 ? NFTList?.balance : ""} */}
          </Typography>
          <Typography
            className={
              artistRelated ? "view-description italic" : "view-description"
            }>
            {TitleDetails?.[1]?.length > 40
              ? TitleDetails?.[1]?.substring(0, 40) + "..."
              : TitleDetails?.[1] ?? ""}
          </Typography>
          <Typography className="view-description">
            {TitleDetails?.[2] ?? ""}
          </Typography>
          <Box className="responsive-card-content">
            <Grid item xs={12}>
              <Typography className="price-title">
                  {getPriceLable({status: status, price: NFTList?.Price, listedPrice: NFTList?.listedOrderInfo?.price})}
              </Typography>
            </Grid>
            <PriceWrapper>
              
              <Grid item xs={12}>
                <CryptoPriceCard price={NFTList?.Price} listedPrice={NFTList?.listedOrderInfo?.price} status={status} isUsd={false} buyNowPrice={NFTList?.Price?.buyNowPrice?.[1]} lastPurchasedPrice={NFTList?.Price?.lastPurchasedPrice?.[1]} makeOfferHighestPrice={NFTList?.Price?.makeOfferHighestPrice?.[1]} isListing={true} />
              </Grid>
            </PriceWrapper>
          </Box>
          <Grid container>
          {getTokenAlignmentStatus(status, NFTList?.Price, NFTList?.listedOrderInfo?.price) && (
            <Grid item xs={6}>
              <CryptoPriceCard price={NFTList?.Price} listedPrice={NFTList?.listedOrderInfo?.price} status={status} isUsd={true} buyNowPrice={NFTList?.Price?.buyNowPrice?.[0]} lastPurchasedPrice={NFTList?.Price?.lastPurchasedPrice?.[0]} makeOfferHighestPrice={NFTList?.Price?.makeOfferHighestPrice?.[0]} isListing={true} />
            </Grid>)}
            <Grid item xs={6} className="token-grid" sx={{ display: "flex", justifyContent: getTokenAlignmentStatus(status, NFTList?.Price, NFTList?.listedOrderInfo?.price) ? "right" : "left"}}>
              {NFTList?.TokenType && (
                <Box className="token-type">
                  <Typography>
                    {`ERC-${NFTList?.TokenType.toLowerCase().replace(
                      "erc",
                      ""
                    )}`}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
)`
.responsive-card-content {
  min-height:15px;
}
.view-title {
    font-family: Mercury Display !important;
    font-weight: 325 !important;
    font-size: 25px !important;
    color: ${palette.black} !important;
    height: 30px !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    line-height: 22px !important;
}
.view-description {
    font-family: BentonSans !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    color: ${palette.gray} !important;
    height: 22px !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
}
.italic {
  font-style: italic !important;
}
.price-title {
  font-family: BentonSans !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  margin-top: 23px !important;
  height: 15px !important;
  color: ${palette.gray} !important
} 

.lastPurchase {
  min-height:15px
}
@media screen and (max-width: ${EViewSize.mobile}) {
  .responsive-card-content {
    min-height: 40px;
  }
}

`;

const PriceWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;
