import { Box, Button } from "@material-ui/core";
import { useRouter } from "next/router";
import { memo, ReactNode } from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Image from "next/image";
import {
  IButton,
  SecondaryButton,
} from "src/components/secondary-wallet/secondaryButton";
import { palette } from "@styles/theme";
import { MediaImageComponent } from "./MediaImageComponet";

interface ICollectionGridItemProps {
  onClickItem: (type: string) => void;
  buttonLoader?: boolean;
  btnName?: string;
  CardContent?: any;
  detailsUrl?: string;
  imageurl: string;
  animationURL?: string;
  btnClass?: string;
  styleId?: string;
  disable?: boolean;
  primaryWalletBtn?: boolean;
  secondImage?: string
  autoPlay?: boolean;
  mute?: boolean;
  mediaImage?: string;
  mediaExtension?: string;
}

export const PrimaryWalletButton = styled(
  ({
    disabled,
    width,
    isLoading,
    primaryWalletNftButton,
    ...props
  }: IButton & { children: ReactNode }) => {
    return (
      <Grid container spacing={2} {...props}>
        <Grid item xs={9}>
          <Button
            {...props}
            disabled={!!disabled}
            style={{
              width: width ?? "100%",
            }}
            onClick={() => {
              if (primaryWalletNftButton) {
                primaryWalletNftButton("viewItem");
              }
            }}
            className={"primaryWalletButton withHover"}
            endIcon={
              isLoading ? (
                <Image
                  unoptimized={true}
                  src={"/images/loading.gif"}
                  alt={"loading"}
                  width={16}
                  height={16}
                />
              ) : null
            }>
            {props.children}
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            className={"primaryWalletButton "}
            onClick={() => {
              if (primaryWalletNftButton) {
                primaryWalletNftButton("transfer");
              }
            }}>
            <Image
              unoptimized={true}
              src={"/images/Vector.svg"}
              alt={"Transfer"}
              width={20}
              height={16}
            />
          </Button>
        </Grid>
      </Grid>
    );
  }
)`
  .primaryWalletButton {
    text-transform: none;
    margin-top: 65px;
    width: 100%;
    height: 50px;
    background: ${palette.white};
    border: 1px solid ${palette.primary_wallet};
    border-radius: 3px;
    cursor: pointer;
    font-family: BentonSans;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.1px;
    color: ${palette.primary_wallet_color};

    &[disabled] {
      color: ${palette.black};
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .withHover {
    &:hover {
      background-color: ${palette.navyBlue};
      color: ${palette.white};
      svg {
        fill: ${palette.white};
      }
    }
  }
`;

//TODO: remove placeholder texts and use real data
export const SecondaryWalletItemCard = styled(
  memo(
    ({
      onClickItem,
      buttonLoader,
      btnName,
      CardContent,
      detailsUrl,
      imageurl,
      btnClass = "secondary",
      styleId,
      disable = false,
      primaryWalletBtn = false,
      animationURL = "",
      secondImage,
      autoPlay = false,
      mute = true,
      mediaExtension,
      mediaImage = "", 
      ...props
    }: ICollectionGridItemProps) => {
      const router = useRouter();
      return (
        <Grid container id={styleId} {...props}>
          <Grid
            item
            xs={12}
            className={"imag-card pos-img"}
            onClick={() => {
              router.push(`${detailsUrl}`, undefined, { shallow: true });
            }}>
            <MediaImageComponent 
              mediaImage={mediaImage}
              mediaExtension={mediaExtension ?? ''}
              image={imageurl}
              animationURL={animationURL}
              secondImage={secondImage ?? ''}
              dynamicSize={ {height: "282px", width: "100%"} }
              layout = "fill"
              objectFit = "contain"
              autoPlay={autoPlay}
              mute={mute}
            />
          </Grid>
          <Grid item xs={12} className="grid-bg">
            <Box className="box-content">
              <Box onClick={() => {
                      router.push(`${detailsUrl}`, undefined, { shallow: true });
                    }}
              >
                {CardContent}
              </Box>
              {primaryWalletBtn ? (
                <PrimaryWalletButton
                  buttontype={btnClass}
                  primaryWalletNftButton={(name: string) => onClickItem(name)}
                  disabled={disable || buttonLoader}
                  isLoading={buttonLoader}>
                  {btnName}
                </PrimaryWalletButton>
              ) : (
                <SecondaryButton
                  buttontype={btnClass}
                  onClick={() => onClickItem("Enquire to List")}
                  className={`btn ${btnClass}`}
                  disabled={disable || buttonLoader}
                  isLoading={buttonLoader}>
                  {btnName}
                </SecondaryButton>
              )}
            </Box>
          </Grid>
        </Grid>
      );
    }
  )
)`
  img {
    border-radius: 0px;
  }
  .pos-img {
    position: relative;
    .play-button {
      position: absolute;
      right: 0;
      bottom: 0;
      cursor: pointer;
    }
  }
  .btn {
    margin-top: 1rem;
    padding: 16px 10px;
    letter-space: 10px;
    width: 100%;
    height: 54px;
    font-weight: 700;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    font-size: 15px;
    text-transform: capitalize;
  }
  .grid-bg {
    background: ${palette.lightWhite};
    border-radius: 0px;
    // height: 280px;
  }
  .imag-card {
    height: 282px;
    width: 100%;
    cursor: pointer;
    border-radius: 5px 5px 0px 0px;
    background-color: ${palette.lightWhite};
  }
  .image-size {
    height: 100%;
    width: 100%;
  }

  .box-content {
    display: flex;
    flex-direction: column;
    padding: 32px 16px 32px 16px;
    height: 100%;

    & .title {
      font-family: Mercury Display;
      font-size: 25px;
      font-weight: 325;
      overflow: hidden;
      height: 37px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & .description {
      font-size: 15px;
      margin-top: 0.6rem;
      height: 22px;
      overflow: hidden;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & .content {
      font-size: 15px;
      height: 50px;
      margin-top: 0.6rem;
      overflow: hidden;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .crypto-price {
    font-family: BentonSans;
    font-size: 10px !important;
    font-weight: 700;
    padding-top: 8px;
    color: ${palette.gray} !important;
    opacity: 0.5;
    letter-spacing: 0.05em;
  }
  .token-grid {
    display: flex;
    .token-type {
      display: inline-block;
      background: #eee;
      padding: 3.5px 8px;
      border-radius: 100px;
      height: 25px;
      p {
        font-family: BentonSans;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        color: #212121;
      }
    }
  }
`;
