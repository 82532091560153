import { EViewSize } from "@enums";
import { Box } from "@material-ui/core";
import { palette } from "@styles/theme";
import {
  formatNumberToUSD,
  getTokenAlignmentStatus,
  truncateDecimal,
} from "@utils/wallet/numberFormet.util";
import { NFTPrice } from "@utils/wallet/wallet";
import React from "react";
import styled from "styled-components";

interface IpriceData {
  status: string;
  price: {
    buyNowPrice: NFTPrice[];
    lastPurchasedPrice: NFTPrice[];
    makeOfferHighestPrice: NFTPrice[];
    makeOfferLatestPrice: NFTPrice[];
  };
  buyNowPrice: NFTPrice;
  lastPurchasedPrice: NFTPrice;
  makeOfferHighestPrice: NFTPrice;
  listedPrice: NFTPrice[];
  isUsd: boolean;
  isListing: boolean;
}

interface IpriceLable {
  status: string;
  price: {
    buyNowPrice: NFTPrice[];
    lastPurchasedPrice: NFTPrice[];
    makeOfferHighestPrice: NFTPrice[];
    makeOfferLatestPrice: NFTPrice[];
  };
  listedPrice: NFTPrice[];
}

interface NFTPriceListPrice {
  value: string | number;
  unit: string;
  type: string;
}
export const CryptoPriceCard = styled(
  ({
    status,
    price,
    buyNowPrice,
    lastPurchasedPrice,
    makeOfferHighestPrice,
    listedPrice,
    isUsd,
    isListing,
    ...props
  }: IpriceData) => {
    const listOrderPrice = (): NFTPriceListPrice => {
      if (isUsd) {
        return {
          ...listedPrice[0],
          value: formatNumberToUSD(listedPrice[0]?.value),
        };
      } else {
        return listedPrice[1];
      }
    };
    return (
      <div {...props}>
        <Box className={isUsd ? "usd-price" : "currency-price"}>
          {getTokenAlignmentStatus(status, price, listedPrice) && (
            <>
              {status === "Make Offer" && isListing ? (
                makeOfferHighestPrice && makeOfferHighestPrice?.value > 0 ? (
                  <>
                    {`${truncateDecimal(makeOfferHighestPrice?.value, 4)} ${
                      makeOfferHighestPrice?.unit
                    }`}
                  </>
                ) : lastPurchasedPrice && lastPurchasedPrice?.value > 0 ? (
                  <>
                    {`${truncateDecimal(lastPurchasedPrice?.value, 4)} ${
                      lastPurchasedPrice?.unit
                    }`}
                  </>
                ) : (
                  <></>
                )
              ) : status === "Buy Now" || status === "Edit Listing" ? (
                <>
                  {(listedPrice && Number(listOrderPrice()?.value) > 0) ||
                  (buyNowPrice && buyNowPrice?.value > 0) ? (
                    <>
                      {`${
                        isUsd
                          ? listOrderPrice()?.value
                          : truncateDecimal(
                              Number(listOrderPrice()?.value) > 0
                                ? listOrderPrice()?.value
                                : buyNowPrice?.value,
                              4
                            )
                      } ${
                        listOrderPrice()?.unit?.length > 0
                          ? listOrderPrice()?.unit
                          : buyNowPrice?.unit
                      }`}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  {lastPurchasedPrice && lastPurchasedPrice?.value > 0 ? (
                    <>
                      {`${truncateDecimal(lastPurchasedPrice?.value, 4)} ${
                        lastPurchasedPrice?.unit
                      }`}
                    </>
                  ) : makeOfferHighestPrice &&
                    makeOfferHighestPrice?.value > 0 ? (
                    <>
                      {`${truncateDecimal(makeOfferHighestPrice?.value, 4)} ${
                        makeOfferHighestPrice?.unit
                      }`}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      </div>
    );
  }
)`
  .currency-price {
    font-size: 15px !important;
    font-weight: 700;
    height: 15px !important;
    display: inline-flex;
    color: ${palette.black} !important;
  }
  .usd-price {
    font-size: 10px !important;
    font-weight: 700;
    padding-top: 5px;
    color: ${palette.gray} !important;
    opacity: 0.5;
    letter-spacing: 0.05em;
  }
  @media screen and (max-width: ${EViewSize.mobile}) {
    .currency-price {
      display: contents;
    }
    .usd-price {
      height: 35px !important;
    }
  }
`;

export const getPriceLable = ({
  status,
  price,
  listedPrice,
}: IpriceLable): string => {
  if (getTokenAlignmentStatus(status, price, listedPrice)) {
    if (status === "Make Offer") {
      if (price?.makeOfferHighestPrice?.length > 0) {
        return "HIGHEST OFFER";
      } else if (price?.lastPurchasedPrice?.length > 0) {
        return "LAST SALE";
      } else {
        return "";
      }
    } else if (status === "Buy Now" || status === "Edit Listing") {
      if (listedPrice?.length > 0 || price?.buyNowPrice?.length > 0) {
        return status === "Edit Listing" ? "PRICE" : "LISTED PRICE";
      } else {
        return "";
      }
    } else if (price?.lastPurchasedPrice?.length > 0) {
      return "LAST SALE";
    } else if (price?.makeOfferHighestPrice?.length > 0) {
      return "HIGHEST OFFER";
    } else {
      return "";
    }
  } else {
    return "";
  }
};
